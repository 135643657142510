import { makeAutoObservable } from "mobx"
import { nanoid } from "nanoid"
import groupBy from "lodash/groupBy"

import { EventSourceMessage } from "@microsoft/fetch-event-source"
import searchService, { StreamMessage } from "@services/search.service"
import {
  AnswerViewType,
  QuestionFeedbackType,
} from "@framework/constants/search-results"
import RestrictionsStore from "@store/restrictions/restrictions.store"
import { AnswerItem } from "@framework/types/search"
import { AvatarData } from "@framework/types/avatar"
import { QueryFilterData } from "@framework/types/query-filter"

import SearchSummaryBlockStore from "../search-summary-block.store"
import FilterEntityStore from "../filter-entity.store"
import SearchAnswersDataStore from "../search-answers-data.store"
import SearchPassagesStore from "../search-passages.store"
import { AnswerSource, extractSources } from "../dataTransformers"
import AnswerFeedbackStore from "../answer-feedback.store"
import SearchMessageBlockStore from "../search-message-block.store"
import SearchFlowStore from "../search-flow.store"
import SearchEntityStore from "../search-entity.store"
import SearchSourcesStore from "../search-sources.store"
import ISearchEntityBlockStore from "../types"
import FactFinderSolutionStore from "./fact-finder-solution.store"

export class FactFinderSolutionController {
  // injections

  restrictionsStore: RestrictionsStore

  factFinderSolutionStore = new FactFinderSolutionStore()

  get searchFlow(): SearchFlowStore {
    return this.factFinderSolutionStore.searchFlowStore
  }

  // state

  // constructor

  constructor(injections: {
    restrictionsStore: RestrictionsStore
    factFinderSolutionStore: FactFinderSolutionStore
  }) {
    this.restrictionsStore = injections.restrictionsStore
    this.factFinderSolutionStore = injections.factFinderSolutionStore

    makeAutoObservable(this)
  }

  // actions

  reset = () => {
    this.searchFlow.reset()
  }

  searchMessageHandler =
    (store: SearchEntityStore) => (response: EventSourceMessage) => {
      try {
        const message = JSON.parse(response.data) as StreamMessage

        const blockId = message.meta?.queryType ?? message.meta?.id ?? nanoid()

        if (message.type === "SEARCH_RESULT") {
          if (!store.hasBlock(blockId)) {
            store.addBlock(new SearchSummaryBlockStore({ id: blockId }))
          }

          const blockStore = store.getBlockById(blockId)

          if (!(blockStore instanceof SearchSummaryBlockStore))
            throw new Error("Wrong Store type")

          const answersDataStore = new SearchAnswersDataStore({
            rawAnswer: message.data.searchResults,
          })

          const passagesStore = new SearchPassagesStore({
            restrictionsStore: this.restrictionsStore,
            searchAnswers: answersDataStore,
            filter: store.filter,
          })

          const sourcesList = extractSources(message.data.searchResults)

          const sourcesStore = new SearchSourcesStore({
            sourcesList,
            restrictionsStore: this.restrictionsStore,
            searchPassages: passagesStore,
            filter: store.filter,
          })

          blockStore.queryType = message.meta?.queryType ?? "DEFAULT"
          blockStore.setAnswersDataStore(answersDataStore)
          blockStore.setPassagesStore(passagesStore)
          blockStore.setSourcesStore(sourcesStore)

          this.updateSources(sourcesStore)(sourcesList)
        }

        if (message.type === "SUMMARY") {
          const { summary } = message.data

          const summaryText = summary.summary ?? summary.text

          const blockId =
            message.meta?.queryType ?? message.meta?.id ?? nanoid()
          if (!store.hasBlock(blockId)) {
            store.addBlock(new SearchSummaryBlockStore({ id: blockId }))
          }
          const blockStore = store.getBlockById(blockId)

          if (!(blockStore instanceof SearchSummaryBlockStore))
            throw new Error("Wrong Store type")

          blockStore.queryType = message.meta?.queryType ?? "DEFAULT"
          blockStore.searchSummary.summary = summaryText
          blockStore.searchSummary.highlights = summary.keyInfoList ?? []
          blockStore.searchSummary.confidence = summary.confidence ?? 0
        }

        if (message.type === "SUMMARY_DELTA") {
          const summaryText = message.data.text ?? ""

          const blockId =
            message.meta?.queryType ?? message.meta?.id ?? nanoid()

          if (!store.hasBlock(blockId)) {
            const blockStore = store.addBlock(
              new SearchSummaryBlockStore({ id: blockId })
            )

            blockStore.queryType = message.meta?.queryType ?? "DEFAULT"
          }

          const blockStore = store.getBlockById(blockId)

          if (!(blockStore instanceof SearchSummaryBlockStore))
            throw new Error("Wrong Store type")

          blockStore.searchSummary.summary =
            (blockStore.searchSummary.summary || "") + summaryText
        }

        if (message.type === "STATUS_MESSAGE") {
          if (!store.hasBlock(blockId)) {
            if (message.meta?.queryType === blockId)
              store.addBlock(
                new SearchSummaryBlockStore({
                  id: blockId,
                })
              )
            else
              store.addBlock(
                new SearchMessageBlockStore({
                  id: blockId,
                })
              )
          }
          const blockStore = store.getBlockById(blockId)

          blockStore.addMessage(message.message)
          blockStore.queryType = message.meta?.queryType ?? "DEFAULT"

          if (message?.meta?.threadId) {
            this.searchFlow.setOldThreadId(message?.meta?.threadId)
          }
        }

        if (message.type === "ERROR") {
          store.setError(message.message ?? "Unexpected error")
        }
      } catch (error) {
        // this.error = "Unexpected service error"
        // TODO
      }
    }

  searchUnstructured = async (searchInstance: SearchEntityStore) => {
    try {
      searchInstance.setLoading(true)

      await searchService.getUnstructuredSearchStream(
        searchInstance.filter.toJson(),
        {
          onmessage: this.searchMessageHandler(searchInstance),
          onerror: (err) => {
            // TODO: Handle errors
            console.error(err)
          },
          onclose: () => {
            searchInstance.setLoading(false)
          },
        }
      )
    } catch (error: any) {
      // TODO: Handle errors
      console.error(error)
    } finally {
      searchInstance.setLoading(false)
    }
  }

  search = async (
    query: string,
    avatar: AvatarData,
    solutionId: string,
    appliedFilters: QueryFilterData[]
  ) => {
    try {
      const appliedFiltersGroup = groupBy(appliedFilters, "type")

      const filter = new FilterEntityStore({
        query,
        avatarName: avatar.name,
        productSolutionId: solutionId,
        avatarId: avatar.id,
        oldThreadId: this.searchFlow.oldThreadId,
        dataTypes: appliedFiltersGroup?.dataType?.map((item) => item.name),
        dataSources: appliedFiltersGroup?.dataSource?.map((item) => item.id),
        categoryIds: appliedFiltersGroup?.categories?.map((item) => item.id),
        productIds: appliedFiltersGroup?.products?.map((item) => item.id),
      })

      const searchInstance = new SearchEntityStore({
        id: nanoid(),
        filter,
      })
      this.searchFlow.pushSearchInstance(searchInstance)
      await this.searchUnstructured(searchInstance)
    } catch (error: any) {
      // TODO: Add support for error handling
      console.log(error)
    }
  }

  updateSources =
    (store: SearchSourcesStore) => async (sources: AnswerSource[]) => {
      try {
        const requiresUpdateIds = sources
          .filter(({ fileSize }) => fileSize == null)
          .map(({ id: source }) => ({
            source,
          }))
        if (requiresUpdateIds.length === 0) return

        const response = await searchService.getFilesInfo(requiresUpdateIds)
        const info = response.data.data
        if (!Array.isArray(info)) throw new Error("Unexpected response format")

        store.applySourcesMetaData(info)
      } catch (error) {
        // ignore errors
      }
    }

  voteForQuestion = async (
    blockStore: ISearchEntityBlockStore,
    avatarName: string,
    avatarId: string,
    question: string,
    feedback: QuestionFeedbackType,
    answerData: string,
    reason: string,
    summaryType?: string,
    productSolutionId?: string,
    rating?: number
  ) => {
    const store = new AnswerFeedbackStore({ feedback, reason })
    try {
      store.isLoading = true
      store.data = answerData

      await searchService.sendAnswerFeedback({
        question,
        answer: JSON.stringify(store.data),
        userFeedback: feedback,
        comment: reason,
        channelName: avatarName,
        avatarId,
        summaryType,
        productSolutionId,
        rating,
      })
      blockStore.setFeedback(store)
    } catch (error) {
      store.error = "Unexpected error while sending feedback"
    } finally {
      store.isLoading = false
    }
    return store.error
  }

  updateAnswerUpVote = async (
    searchId: string,
    passage: AnswerItem,
    voted: boolean,
    answerType: AnswerViewType,
    avatarId: string
  ): Promise<string | null> => {
    const searchInstance = this.searchFlow.getById(searchId)
    const answer = passage
    try {
      if (!(searchInstance instanceof SearchEntityStore))
        throw new Error(
          "This answers data store does not support voting feature yet"
        )

      if (answer == null) throw new Error("passageId is expired")

      const newVote = voted ? 1 : 0
      const requestAnswer = { ...answer.value, current_user_vote: newVote }

      await searchService.upVoteAnswer(
        searchInstance.filter.searchQuery,
        requestAnswer,
        answerType,
        searchInstance.filter.searchAvatar,
        avatarId
      )

      // eslint-disable-next-line eqeqeq
      answer.value.current_user_vote = newVote
    } catch (error) {
      return `Failed to ${voted ? "upvote" : "downvote"} passage`
    }
    return null
  }
}

export default FactFinderSolutionController
