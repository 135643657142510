import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useFormik } from "formik"
import * as yup from "yup"
import { useAlert } from "react-alert"

import BaseModal from "@components/modals/components/BaseModal/BaseModal"
import ModalTitle from "@components/modals/components/ModalTitle/ModalTitle"
import useModal from "@components/modals/useModal"
import TextInput from "@components/ui/TextInput/TextInput"
import Button from "@components/ui/Button/Button"
import DocumentIcon from "@components/ui/Icon/DocumentIcon"
import { DataConnectorSourceName } from "@framework/types/upload"
import { IconName } from "@components/ui/Icon/IconName"
import { DocumentIconType } from "@framework/types/utils"
import Text from "@components/ui/Typography/Text"
import SelectOption from "@components/ui/Dropdown/SelectOption"
import Templates from "@components/ui/Templates"
import { useController, useStore } from "@store"
import { CreateContentSourceParams } from "@framework/types/content-manager"
import Icon from "@components/ui/Icon/Icon"
import TooltipContainer from "@components/ui/Tooltip/TooltipContainer"
import Tooltip from "@components/ui/Tooltip/Tooltip"
import Chip from "@components/ui/Chip/Chip"

import { ModalsTypes } from "../constants"
import SharePointConfig from "./SharePointConfig"

import styles from "./ContentManagerConfigFormModal.module.sass"

export type ContentManagerConfigFormModalProps = {
  sourceName: DataConnectorSourceName
  icon?: DocumentIconType | IconName
  label: string
}

const validationSchema = yup.object({
  clientId: yup.string().required("Client ID is required").trim().defined(),
  clientSecret: yup
    .string()
    .required("Client Secret is required")
    .trim()
    .defined(),
  contentType: yup.string().required("Content Type is required").defined(),
  domain: yup.string().when("enumerateAllSites", {
    is: false,
    then: yup
      .string()
      .required("Domain is required")
      .trim()
      .notOneOf(
        ["*"],
        "Domain cannot contain '*' when 'Enumerate All Sites' is false"
      )
      .defined(),
    otherwise: yup.string().required("Domain is required").trim().defined(),
  }),
  enumerateAllSites: yup.boolean(),
  fetchSubsites: yup.boolean(),
  name: yup
    .string()
    .required("Name is required")
    .trim()
    .matches(
      /^[a-z0-9-]+$/,
      "Name must be alphanumeric, lowercase, and can only contain '-' as a special character"
    )
    .defined(),
  tenantId: yup.string().when("type", {
    is: "sharepoint_online",
    then: yup.string().required("Tenant ID is required").trim().defined(),
    otherwise: yup.string().trim(),
  }),
  tenantName: yup.string().when("type", {
    is: "sharepoint_online",
    then: yup.string().required("Tenant Name is required").trim().defined(),
    otherwise: yup.string().trim(),
  }),
  type: yup.string(),
  useTextExtractionService: yup.boolean(),
})

const ContentManagerConfigFormModal: React.FC<ContentManagerConfigFormModalProps> =
  observer(({ sourceName, icon, label }) => {
    const [showSecret, setShowSecret] = useState(false)
    const initialValues: CreateContentSourceParams = {
      name: "",
      domain: "",
      clientId: "",
      clientSecret: "",
      contentType: "",
      tenantId: "",
      tenantName: "",
      type: sourceName,
      useTextExtractionService: false,
      fetchSubsites: false,
      enumerateAllSites: false,
    }
    const alert = useAlert()

    const { hideModal, hideAllModals } = useModal(
      ModalsTypes.CONTENT_MANAGER_CONFIG_FORM_MODAL
    )

    const { contentManagerController } = useController()
    const { contentManagerStore, allDatatypesStore } = useStore()
    const {
      datatypesController: { loadAllDataTypes },
    } = useController()

    const contentTypes = allDatatypesStore?.state?.data

    const { loadingCreatedContentSource } = contentManagerStore || {}
    const formik = useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (form) => {
        const success = await contentManagerController.createContentSource(form)
        if (success) {
          alert.success("Successfully created content source")
          hideAllModals()
        } else {
          alert.error("Failed to create")
        }
      },
    })
    useEffect(() => {
      formik.validateForm()
    }, [sourceName])

    let domainHeading = "Domain"
    let domainPlaceholder = "Enter domain"
    if (sourceName === "sharepoint_online") {
      domainHeading = "Site Collection"
      domainPlaceholder = "Enter Comma-separated list of sites"
    }

    useEffect(() => {
      loadAllDataTypes()
    }, [])

    return (
      <BaseModal
        className={styles.root}
        title={
          <ModalTitle
            titleText={
              <div className={styles.titleContainer}>
                {icon && <DocumentIcon icon={icon} />}
                {label}
              </div>
            }
          />
        }
        containerClassName={styles.container}
      >
        <Templates.RollScript
          footerSocket={
            <div className={styles.footer}>
              <Button
                variant="outlined"
                size="medium"
                onClick={hideModal}
                disabled={loadingCreatedContentSource}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={loadingCreatedContentSource}
                size="medium"
                onClick={formik.submitForm}
              >
                Connect
              </Button>
            </div>
          }
        >
          <form className={styles.form} onSubmit={formik.handleSubmit}>
            <div className={styles.inputsWrapper}>
              <div className={styles.inputContainer}>
                <Text variant="body2">Connector Name</Text>
                <TextInput
                  name="name"
                  placeholder="Enter name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  withError={!!(formik.touched.name && formik.errors.name)}
                  after={
                    formik.touched.name && formik.errors.name ? (
                      <Tooltip
                        content={
                          <TooltipContainer placement="left" color="primary">
                            Names should be lowercase and
                            <br /> cannot contain spaces or special characters
                          </TooltipContainer>
                        }
                      >
                        <Chip color="red" className={styles.error}>
                          <Icon name="info" /> Error
                        </Chip>
                      </Tooltip>
                    ) : null
                  }
                />
              </div>
              <div className={styles.inputContainer}>
                <Text variant="body2">{domainHeading}</Text>
                <TextInput
                  name="domain"
                  placeholder={domainPlaceholder}
                  value={formik.values.domain}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  withError={!!(formik.touched.domain && formik.errors.domain)}
                  after={
                    formik.touched.domain && formik.errors.domain ? (
                      <Tooltip
                        content={
                          <TooltipContainer placement="left" color="primary">
                            {formik.errors.domain}
                          </TooltipContainer>
                        }
                      >
                        <Chip color="red" className={styles.error}>
                          <Icon name="info" /> Error
                        </Chip>
                      </Tooltip>
                    ) : null
                  }
                />
              </div>
              <div className={styles.inputContainer}>
                <Text variant="body2">Client ID</Text>
                <TextInput
                  name="clientId"
                  placeholder="Enter client ID"
                  value={formik.values.clientId}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  withError={
                    !!(formik.touched.clientId && formik.errors.clientId)
                  }
                />
              </div>
              <div className={styles.inputContainer}>
                <Text variant="body2">Client Secret</Text>
                <TextInput
                  name="clientSecret"
                  placeholder="Enter client secret"
                  type={showSecret ? "text" : "password"}
                  value={formik.values.clientSecret}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  withError={
                    !!(
                      formik.touched.clientSecret && formik.errors.clientSecret
                    )
                  }
                  after={
                    <Icon
                      name={showSecret ? "eye-close" : "eye"}
                      className={styles.eyeIcon}
                      onClick={() => setShowSecret(!showSecret)}
                    />
                  }
                />
              </div>
              <div className={styles.inputContainer}>
                <Text variant="body2">Content Type</Text>
                <SelectOption
                  placement="top"
                  value={
                    contentTypes.find(
                      (contentType) =>
                        contentType.id === formik.values.contentType
                    )?.name
                  }
                  options={contentTypes?.map((item) => item.name)}
                  placeholder="Select content type"
                  onChange={(value) =>
                    formik.setFieldValue(
                      "contentType",
                      contentTypes.find(
                        (contentType) => contentType.name === value
                      )?.id
                    )
                  }
                  error={
                    formik.touched.contentType
                      ? formik.errors.contentType
                      : null
                  }
                />
              </div>
              {sourceName === "sharepoint_online" && (
                <SharePointConfig formik={formik} />
              )}
            </div>
          </form>
        </Templates.RollScript>
      </BaseModal>
    )
  })

export default ContentManagerConfigFormModal
