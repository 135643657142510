import React from "react"
import { FormikProps } from "formik"

import TextInput from "@components/ui/TextInput/TextInput"
import Text from "@components/ui/Typography/Text"
import Switch from "@components/ui/Switch/Switch"
import { CreateContentSourceParams } from "@framework/types/content-manager"

import styles from "./ContentManagerConfigFormModal.module.sass"

const SharePointConfig: React.FC<{
  formik: FormikProps<CreateContentSourceParams>
}> = ({ formik }) => (
  <>
    <div className={styles.inputContainer}>
      <Text variant="body2">Tenant ID</Text>
      <TextInput
        name="tenantId"
        placeholder="Enter tenant ID"
        value={formik.values.tenantId}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        withError={!!(formik.touched.tenantId && formik.errors.tenantId)}
      />
    </div>
    <div className={styles.inputContainer}>
      <Text variant="body2">Tenant Name</Text>
      <TextInput
        name="tenantName"
        placeholder="Enter tenant name"
        value={formik.values.tenantName}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        withError={!!(formik.touched.tenantName && formik.errors.tenantName)}
      />
    </div>
    <div className={styles.inputContainer}>
      <Text variant="body2">Use Text Extraction Service</Text>
      <Switch
        items={[
          { name: "useTextExtractionService", value: "Yes" },
          { name: "disableTextExtractionService", value: "No" },
        ]}
        checked={
          formik.values.useTextExtractionService
            ? "useTextExtractionService"
            : "disableTextExtractionService"
        }
        onChange={(name) =>
          formik.setFieldValue(
            "useTextExtractionService",
            name === "useTextExtractionService"
          )
        }
      />
    </div>
    <div className={styles.inputContainer}>
      <Text variant="body2">Fetch Subsites</Text>
      <Switch
        items={[
          { name: "fetchSubsites", value: "Yes" },
          { name: "disableFetchSubsites", value: "No" },
        ]}
        checked={
          formik.values.fetchSubsites ? "fetchSubsites" : "disableFetchSubsites"
        }
        onChange={(name) =>
          formik.setFieldValue("fetchSubsites", name === "fetchSubsites")
        }
      />
    </div>
    <div className={styles.inputContainer}>
      <Text variant="body2">Enumerate All Sites</Text>
      <Switch
        items={[
          { name: "enumerateAllSites", value: "Yes" },
          { name: "disableEnumerateAllSites", value: "No" },
        ]}
        checked={
          formik.values.enumerateAllSites
            ? "enumerateAllSites"
            : "disableEnumerateAllSites"
        }
        onChange={(name) =>
          formik.setFieldValue(
            "enumerateAllSites",
            name === "enumerateAllSites"
          )
        }
      />
    </div>
  </>
)

export default SharePointConfig
